import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { userActions } from '../../actions/user.actions';
import './LoginPage.scss';
// import loginImg from '../../assets/images/login.svg';
import { validateAll, validateData } from './validation';
import { isLogin } from '../../middleware/Auth';
import { TnCModal } from '../common/confirm-modal/TnCModal';
import { ErrorModal } from '../common/confirm-modal/ErrorModal';
import { eventHandler } from '../../App';


function LoginPage() {
    const [inputs, setInputs] = useState({
        userEmail: '',
        password: '',
        deviceType: 'web'
    });
    const [error, setError] = useState({ userEmail: "", password: "" });
    const { userEmail, password } = inputs;
    const [agree, setAgree] = useState(false);
    const dispatch = useDispatch();

    window.removeEventListener("beforeunload", eventHandler);

    useEffect(() => {
        if (isLogin()) {
            dispatch(userActions.logout());
        }
    }, [dispatch]);

    function handleChange(e: any) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
        let errorObj: any = error;
        validateData(name, value, errorObj);
        setError((state: any) => ({ ...state, errorObj }));
    }

    function handleSubmit(e: any) {
        e.preventDefault();
        let errorObj = error;
        const isValid = validateAll(inputs, errorObj);
        setError((state: any) => ({ ...state, errorObj }));
        if (isValid) {
            if (agree) {
                dispatch(userActions.login({ ...inputs }));
            } else {
                ErrorModal({ message: "Please accept terms and conditions" });
            }
        }
    }
    const checkboxHandler = () => {
        setAgree(!agree);
    }
    const handleTerms = () => {
        TnCModal();
    }

    return (
        <div className='login-form-cont'>
            <div className="col-md-4 offset-md-7 py-4">
                <div className=' login-bg-card'>
                    <h2 className='search-h2'>Lattice FPGA<br/>Lot Search Portal</h2>
                    <div className='d-flex justify-content-center card'>
                        <h2 className="d-flex justify-content-center mt-4">Sign In to Your Account</h2>
                        <form name="form" className='p-4' onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label className='form-label'>Email Address</label>
                                <input type="text" name="userEmail" value={userEmail} onChange={handleChange} className={'form-control' + (error.userEmail ? ' is-invalid' : '')} />
                                {error.userEmail &&
                                    <div className="invalid-feedback">{error.userEmail}</div>
                                }
                            </div>
                            <div className="form-group">
                                <label className='form-label'>Password</label>
                                <input type="password" name="password" value={password} onChange={handleChange} className={'form-control' + (error.password ? ' is-invalid' : '')} />
                                {error.password &&
                                    <div className="invalid-feedback">{error.password}</div>
                                }
                            </div>
                            <div className="form-group">
                                <input type="checkbox" className="form-check-input" onChange={checkboxHandler} />
                                <label className="p-2">Agree to <span className="term-label" onClick={handleTerms}>Terms and Conditions</span></label>
                            </div>
                            <div className="button-group mt-3">
                                <button className="btn btn-primary w-100">Sign In</button>
                                <Link to="/forgot-pwd" className="d-flex justify-content-center btn btn-link mt-2 forgot-pwd">Forgot Password?</Link>
                            </div>
                            <div>
                                <label className="d-flex justify-content-center mt-1">Need an account? <Link to="/register" className="px-2 term-label">Register</Link></label>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { LoginPage };