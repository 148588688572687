import axios from 'axios';
import { getUserToken } from '../middleware/Auth';

let loadingCount:number=0;
let authHeader = {
    'Authorization': "",
    'Content-Type': "application/json",
    "loginUserName": "",
    "email": "",
    "loginUserId": "",
    'Access-Control-Allow-Origin':"*",
}
// Create instance
let fetch = axios.create({
    baseURL:'https://latticelink.latticesemi.com:8080/onboard',
    timeout: 3000 * 10,
    headers: authHeader,
});

let productFetch = axios.create({
    baseURL:'https://latticelink.latticesemi.com:8080/search',
    timeout: 3000 * 10,
    headers: authHeader,
});

productFetch.interceptors.request.use(async (config:any) => {
    return setConfigs(config);
}, async (error:any) => {
    return returnPromise(error);
});

productFetch.interceptors.response.use(async (response:any) => {
    removeLoader();
    return response;
}, async (error:any) => {
    return returnPromise(error);
});


fetch.interceptors.request.use(async (config:any) => {
    return setConfigs(config);
}, async (error:any) => {
    return returnPromise(error);
});

fetch.interceptors.response.use(async (response:any) => {
    removeLoader();
    return response;
}, async (error:any) => {
    returnPromise(error);
});

const setConfigs=(config:any)=>{
    addLoader();
    let userInfo:any = getUserToken();
    config.headers.Authorization = userInfo ? "Bearer " + userInfo.token:"";
    config.headers.loginUserName = userInfo ? userInfo.userName:"";
    config.headers.loginUserId = userInfo ? userInfo.id:"";
    return config; 
}
const returnPromise=(error:any)=>{
    removeLoader();
    return Promise.reject(error);
}


const removeLoader = () => {
    loadingCount= loadingCount-1;
    if(loadingCount === 0){
        let loderCnt: any = document.getElementById('loader-cnt');
        loderCnt.classList.remove("loader-cnt");
        let loader: any = document.getElementById('loader-div');
        loader.classList.remove("loader");
    }
}
const addLoader = () => {
    loadingCount= loadingCount+1;
    let loderCnt: any = document.getElementById('loader-cnt');
    loderCnt.classList.add("loader-cnt");
    let loader: any = document.getElementById('loader-div');
    loader.classList.add("loader");
}
export { fetch,productFetch };
