import { Link } from "react-router-dom";
import { getRoleAccess, getUserInfo } from "../../Utils/role-access";
import './OnboardCustomerPage.scss';
import { SearchResultPage } from "../search/search-result/SearchResultPage";
import { useRef, useState } from 'react';
import { toPascalCase } from "../../Utils/helpers";

const OnboardCustomerPage = () => {
    // const { lotIdList } = useSelector((state: any) => state.user);
    const [query, setQuery] = useState("");
    const [searchBy, setSearchBy] = useState("LOTID");
    const userInfo = getUserInfo();
    const [show, setShow] = useState(false);
    const childRef: any = useRef();

    const handleSearch = (e: any) => {
        setSearchBy(e.target.value);
        setQuery("");
        setShow(false);
    }
    const handleChange = (e: any) => {
        const { value } = e.target;
        setQuery(value);
    }

    const handleShow = (e: any) => {
        if (query && e.key === 'Enter') {
            setShow(true);
        }
    }

    const onKeyHandle = () => {
        if (searchBy === "LOTID") {
            childRef.current.getSearchResult(query);
        } else {
            childRef.current.getUnitCode(query);
        }
    }

    return (
        <div>
            {getRoleAccess('onboard') && <div className="section-onbord">
                <div className='container'>
                    <div className="row">
                        <div className="col-md-6">
                            <h1 className='title-cust mt-5'>Welcome {toPascalCase(userInfo.userName)}</h1>
                            <p className='desc mt-2 desp-arial'>Search and view your products</p>
                        </div>
                    </div>
                </div>

            </div >}

            {getRoleAccess('view-add-user-customer') && <div className="section-onbord ">
                <div className='container'>
                    <div className="row">
                        <div className="col-md-6">
                            <div><h1 className='title-cust mt-3'>User Onboarding</h1>
                                <p className='desc mt-2 desp-arial'> Onboarding process for adding, viewing, and modifying users</p>
                                <div className="btn-cont-onboard  mt-4">
                                    <Link to="/addUser" className='btn btn-primary desp-arial'>Add new user</Link>
                                    <Link to="/viewUsers" className="btn btn-primary mt-3 desp-arial">View / Modify Users</Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="onboard">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <h1 className="title-cust">Customer Onboarding</h1>
                                <p className="mb-0 desp-arial desc">Onboarding process for adding, viewing, and modifying customers</p>
                                <div className="btn-cont-onboard mt-4">
                                    <Link to="/addCustomer" className="btn btn-primary desp-arial" type="submit">Add new customer</Link>
                                    <Link to="/viewCustomers" className="btn btn-primary mt-3 desp-arial">View / Modify Customers</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            {getRoleAccess('search') &&
                <div>
                    <div className="py-5 s-color">
                        <div className="search-select container">
                            <label className="p-1 desp-arial">Search By</label>
                            <div className="d-flex">
                                <select className="form-control w-50 me-2 mt-2 form-select" onChange={handleSearch}>
                                    <option value="LOTID">Lot ID</option>
                                    <option value="UNITCODE">2D Code ID</option>
                                </select>
                            </div>
                            <div>
                                <div className="d-flex mt-3">
                                    <div className="w-50 me-2">
                                        <input
                                            className="form-control" type="search" value={query} placeholder="Search" aria-label="Search"
                                            onChange={handleChange} onKeyPress={(e) => { e.key === 'Enter' && query && onKeyHandle(); handleShow(e) }} />
                                    </div>
                                    <button className="btn btn-primary" type="submit" onClick={() => { onKeyHandle(); setShow(true) }} disabled={!query}>Search</button>
                                </div>
                                <label className="me-2 label-example">{searchBy === "LOTID" ? <span>Enter Lot ID ( Example: 1050TT01 )</span> : <span>Enter 2D Code ID( Example: 2221LCXX000001 )</span>}</label>
                            </div>
                        </div>
                    </div>
                    <div className={(show ? 'display-block' : 'hide-block')}>
                        <SearchResultPage ref={childRef} />
                    </div>
                </div>
            }
        </div >
    );

}
export { OnboardCustomerPage };