
import { AxiosResponse } from 'axios';
import { ErrorModal } from '../components/common/confirm-modal/ErrorModal';
import { SuccessModal } from '../components/common/confirm-modal/SuccessModal';
import { userConstants } from '../constants/user.constants';
import { fetch, productFetch } from '../Utils/api-route';
import { history } from '../Utils/history';
import { encryptText } from '../Utils/helpers';
import { getUserInfo } from '../Utils/role-access';
import { LoginErrorModal } from '../components/common/confirm-modal/LoginErrorModal';
import { SuccessLoginModal } from '../components/common/confirm-modal/SuccessLoginModal';
import { ErrorModalSearch } from '../components/common/confirm-modal/ErrorModalSearch';

export const userActions = {
    addUser,
    login,
    logout,
    getUsers,
    addCustomer,
    getCustomers,
    getCustomerNames,
    updateUser,
    updateCustomer,
    searchDevice,
    saveSelfUser,
    deleteSelfUser,
    searchUnitCode,
    // getLotId
};

function addUser(data: any) {
    return (dispatch: Function) => {
        dispatch(request());
        fetch({
            method: 'post',
            url: 'v1/user/save',
            data: data
        }).then(function (response) {
            if (response.data.status === 200) {
                dispatch(success(response));
                history.push('/onboard-success', { data: response.data.result, type: "user" });
            } else {
                ErrorModal(response.data);
            }
        }).catch(error => {
            dispatch(failure(error.message));
            ErrorModal(error);
        });

    };
    function request() { return { type: userConstants.POST_USERREG_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: userConstants.POST_USERREG_SUCCESS, response } }
    function failure(error: any) { return { type: userConstants.POST_USERREG_FAILURE, error } }
}

function addCustomer(data: any) {
    return (dispatch: Function) => {
        dispatch(request());
        fetch({
            method: 'post',
            url: 'v1/customer/save',
            data: data
        }).then(function (response) {
            if (response.data.status === 200) {
                dispatch(success(response));
                history.push('/onboard-success', { data: response.data.result, type: "customer" });
            } else {
                ErrorModal(response.data);
            }
        }).catch(error => {
            dispatch(failure(error.message));
            ErrorModal(error);
        });

    };
    function request() { return { type: userConstants.POST_USERREG_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: userConstants.POST_USERREG_SUCCESS, response } }
    function failure(error: any) { return { type: userConstants.POST_USERREG_FAILURE, error } }
}


function login(data: any) {
    let info = JSON.parse(JSON.stringify(data));
    info.password = encryptText(data.password);
    return (dispatch: Function) => {
        dispatch(request());
        fetch({
            method: 'post',
            url: 'v1/authenticate/login',
            data: info
        }).then(function (response) {
            if (response.data.status === 200) {
                localStorage.setItem('user', JSON.stringify(response.data.result));
                dispatch(success(response));
                history.push("/search");
            } else if (response.data.status === 417) {
                // LoginErrorModal({message:response.data.message,link:response.data.result});
                LoginErrorModal({
                    message: response.data.message, confirm: "Reset", cancel: "Close", onValueChange: function onValueChange(confirm: any) {
                        if (confirm) {
                            history.push(response.data.result);
                        }
                    }
                });
            }
            else {
                ErrorModal(response.data);
            }
        }).catch(error => {
            dispatch(failure(error.message));
            // ErrorModal({ message: "Network Error !" });
            ErrorModal(error);
        });

    };
    function request() { return { type: userConstants.POST_USERLOGIN_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: userConstants.POST_USERLOGIN_SUCCESS, response } }
    function failure(error: any) { return { type: userConstants.POST_USERLOGIN_FAILURE, error } }
}

function logout() {
    const userInfo = getUserInfo();
    return (dispatch: Function) => {
        dispatch(request());
        fetch({
            method: 'post',
            url: 'v1/authenticate/logout',
            data: { userEmail: userInfo.userEmail }
        }).then(function (response) {
            if (response.data.status === 200) {
                localStorage.removeItem('user');
                // dispatch(success(response));
                // history.push("/signin");
                history.push("/signin");
                dispatch(success(response));
            } else {
                ErrorModal(response.data);
            }
        }).catch(error => {
            dispatch(failure(error.message));
            ErrorModal(error);
        });
        function request() { return { type: userConstants.LOGOUT_REQUEST } }
        function success(response: AxiosResponse<any>) { return { type: userConstants.LOGOUT_SUCCESS, response } }
        function failure(error: any) { return { type: userConstants.LOGOUT_FAILURE, error } }
    }
}

function getCustomers() {
    return (dispatch: Function) => {
        dispatch(request());
        fetch({
            method: 'get',
            url: 'v1/customer/list',
        }).then(function (response) {
            dispatch(success(response));
        }).catch(error => {
            dispatch(failure(error.message));
            ErrorModal(error);
        });

    };
    function request() { return { type: userConstants.GET_CUSTOMERLIST_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: userConstants.GET_CUSTOMERLIST_SUCCESS, response } }
    function failure(error: any) { return { type: userConstants.GET_CUSTOMERLIST_FAILURE, error } }
}


function getUsers() {
    return (dispatch: Function) => {
        dispatch(request());
        fetch({
            method: 'get',
            url: 'v1/user/list-all',
        }).then(function (response) {
            dispatch(success(response));
        }).catch(error => {
            dispatch(failure(error.message));
            ErrorModal(error);
        });

    };
    function request() { return { type: userConstants.GET_USERLIST_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: userConstants.GET_USERLIST_SUCCESS, response } }
    function failure(error: any) { return { type: userConstants.GET_USERLIST_FAILURE, error } }
}

function getCustomerNames() {
    return (dispatch: Function) => {
        dispatch(request());
        fetch({
            method: 'get',
            url: 'v1/customer/list-name',
        }).then(function (response) {
            dispatch(success(response));
        }).catch(error => {
            dispatch(failure(error.message));
            ErrorModal(error);
        });

    };
    function request() { return { type: userConstants.GET_CUSTOMERNAME_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: userConstants.GET_CUSTOMERNAME_SUCCESS, response } }
    function failure(error: any) { return { type: userConstants.GET_CUSTOMERNAME_FAILURE, error } }
}

function updateCustomer(data: any) {
    return (dispatch: Function) => {
        dispatch(request());
        fetch({
            method: 'put',
            url: 'v1/customer/update',
            data: data
        }).then(function (response) {
            if (response.data.status === 200) {
                dispatch(success(response));
                SuccessModal(response.data.message);
                history.push("/viewCustomers");
            } else {
                ErrorModal(response.data);
            }
        }).catch(error => {
            dispatch(failure(error.message));
            ErrorModal(error);
        });

    };
    function request() { return { type: userConstants.POST_USERREG_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: userConstants.POST_USERREG_SUCCESS, response } }
    function failure(error: any) { return { type: userConstants.POST_USERREG_FAILURE, error } }
}


function updateUser(data: any) {
    return (dispatch: Function) => {
        dispatch(request());
        fetch({
            method: 'put',
            url: 'v1/user/update',
            data: data
        }).then(function (response) {
            if (response.data.status === 200) {
                dispatch(success(response));
                SuccessModal(response.data.message);
                history.push("/viewUsers");
            } else {
                ErrorModal(response.data);
            }
        }).catch(error => {
            dispatch(failure(error.message));
            ErrorModal(error);
        });

    };
    function request() { return { type: userConstants.POST_USERREG_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: userConstants.POST_USERREG_SUCCESS, response } }
    function failure(error: any) { return { type: userConstants.POST_USERREG_FAILURE, error } }
}

function searchDevice(id: any) {
    return (dispatch: Function) => {
        dispatch(request());
        productFetch({
            method: 'get',
            url: 'v1/product/lotId/' + id,
        }).then(function (response) {
            if (response.data.status === 200) {
                dispatch(success(response));
            } else {
                ErrorModalSearch(response.data);
            }
        }).catch(error => {
            dispatch(failure(error.message));
            ErrorModalSearch(error);
        });

    };
    function request() { return { type: userConstants.SEARCH_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: userConstants.SEARCH_SUCCESS, response } }
    function failure(error: any) { return { type: userConstants.SEARCH_FAILURE, error } }
}

function saveSelfUser(data: any) {
    return (dispatch: Function) => {
        dispatch(request());
        fetch({
            method: 'post',
            url: 'v1/user/self-register',
            data: data
        }).then(function (response) {
            if (response.data.status === 200) {
                dispatch(success(response));
                // "Successfully registered with emailId "+response.data.result.userEmail
                SuccessLoginModal({
                    message: response.data.message, ok: "Sign In", onValueChange: function onValueChange() {
                        history.push("/signin");
                    }
                });

            } else {
                ErrorModal(response.data);
            }
        }).catch(error => {
            dispatch(failure(error.message));
            ErrorModal(error);
        });

    };
    function request() { return { type: userConstants.SAVE_USER_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: userConstants.SAVE_USER_SUCCESS, response } }
    function failure(error: any) { return { type: userConstants.SAVE_USER_FAILURE, error } }
}
function deleteSelfUser(data: any) {
    return (dispatch: Function) => {
        dispatch(request());
        fetch({
            method: 'get',
            url: 'v1/delete/user/'+data.userEmail+"/"+data.userName,
            data: data
        }).then(function (response) {
            if (response.data.status === 200) {
                dispatch(success(response));
                // "Successfully registered with emailId "+response.data.result.userEmail
                SuccessLoginModal({
                    message: response.data.message, ok: "Sign In", onValueChange: function onValueChange() {
                        history.push("/signin");
                    }
                });

            } else {
                ErrorModal(response.data);
            }
        }).catch(error => {
            dispatch(failure(error.message));
            ErrorModal(error);
        });

    };
    function request() { return { type: userConstants.SAVE_USER_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: userConstants.SAVE_USER_SUCCESS, response } }
    function failure(error: any) { return { type: userConstants.SAVE_USER_FAILURE, error } }
}

function searchUnitCode(unitcode: any) {
    return (dispatch: Function) => {
        dispatch(request());
        productFetch({
            method: 'get',
            url: 'v1/product/unitCode/' + unitcode,
        }).then(function (response) {
            if (response.data.status === 200) {
                dispatch(success(response));
            } else {
                ErrorModalSearch(response.data);
            }
        }).catch(error => {
            dispatch(failure(error.message));
            ErrorModalSearch(error);
        });

    };
    function request() { return { type: userConstants.SEARCH_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: userConstants.SEARCH_SUCCESS, response } }
    function failure(error: any) { return { type: userConstants.SEARCH_FAILURE, error } }
}


// function getLotId() {
//     return (dispatch: Function) => {
//         dispatch(request());
//         productFetch({
//             method: 'get',
//             url: 'v1/product/getLotIdList',
//         }).then(function (response) {
//             dispatch(success(response));
//         }).catch(error => {
//             dispatch(failure(error.message));
//             ErrorModal(error);
//         });

//     };
//     function request() { return { type: userConstants.GET_LOTIDLIST_REQUEST } }
//     function success(response: AxiosResponse<any>) { return { type: userConstants.GET_LOTIDLIST_SUCCESS, response } }
//     function failure(error: any) { return { type: userConstants.GET_LOTIDLIST_FAILURE, error } }
// }